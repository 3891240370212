import React from 'react'
import DatePicker from '@mui/lab/DatePicker';
import { Field, FastField } from 'formik'
import moment from 'moment'
import { InputAdornment, FormControl, TextField, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import IconButton from '@mui/material/IconButton';
import {useFieldPrefix} from '../form/Form';
import { dateFromMoment, dateToMoment, dateToStr } from '../../../utils.js';
import { parseDate } from '../../../utils.js';
import {createField} from './createField';
import _ from 'lodash'

const format = (value) => {
  const ret = value ? moment(value, 'YYYY.MM.DD') : null;
  return ret;
}
const parse = (value) => {
  return value && value.isValid() ? moment(value).format('YYYY-MM-DD') : null;
};

class DateText extends React.Component {
  prev = '';
  state = { hetnapja: '' }

  render() {
    let { inputProps, ...other } = this.props;
   // console.log("DateText",{other});
    const { value, onChange } = inputProps;
   // console.log("inputProps.value", value);
    inputProps = {
      ...inputProps,      
      placeholder: other.placeholder || 'hhnn v. ééhhnn v. ééééhhnn',
      onChange: (ev) => {
   //     console.log("onChange", ev.target.value);
        let v = ev.target.value;
        if (v.length < this.prev.length) {
        //  console.log("Törlés", v, this.prev);
          this.prev = v;
          onChange(ev);
          return;
        } else {
        //  console.log("gépelés", v, this.prev);
        }

        let d = parseDate(v);
     //   console.log("parsed", v, d);
        if (d) {
          ev.target.value = dateToStr(d);
          this.setState({ hetnapja: d.format('dddd') });
        }
        this.prev = ev.target.value;

        onChange(ev);
      }
    };

    return <TextField fullWidth variant="standard" {...other} inputProps={inputProps} />
  }
};

export class DatumInput extends React.Component {
  onClick = (e) => e.stopPropagation();


  render() {
   // console.log("DatumInput", this.props);
  const error = this.props.error || _.get(this.props.errors, this.props.name);
  return (
      
      <FormControl margin={this.props.margin} onClick={this.onClick}>
        <DatePicker
          //          minDateMessage='Túl korai a kiválasztott időpont'
          //          maxDateMessage='Túl távoli a kiválasztott időpont'
          //          invalidDateMessage='Érvénytelen dátum'
          //          invalidLabel=''
          //          emptyLabel=''
          cancelText='Mégsem'
          clearable
          clearText='Töröl' 
          disableMaskedInput
          inputFormat='YYYY.MM.DD'         
          //mask="YYYY.MM.DD"
          autoOk
          renderInput={(params) => {
//            console.log("DatumInput.renderInput",params);
            let nap = '';
            if (!this.props.minimal && this.props.value && this.props.value.isValid()) {
              nap = this.props.value.format('dddd');
            }
            
            return <>
              <DateText {...params} InputLabelProps={{shrink:true}} placeholder={this.props.placeholder} helperText={error}/>
              {this.props.dayOfWeek && <div style={{ position: "relative",height:0 }}>
                <Typography variant="body1" sx={{ color: 'text.secondary', position: 'absolute', top: -27, right: 32 }}>{nap}</Typography>
              </div>}
            </>
          }}

          {...this.props}

        />
      </FormControl>
    );
  }
}

class DateInput extends React.Component {

  handleChange = (value) => {
    let { form, field, calculate } = this.props;
    const val = parse(value);
    form.setFieldValue(field.name, val);
    if (calculate) {
      const oldValue = field.value;
      field.value = val;
      calculate({ oldValue, newValue: field.value, field, form })
    }
  }


  render() {

    const { field, form, calculate, ...other } = this.props;
    const value = format(field.value);
    return (
      <DatumInput
      margin="normal"
        {...field}
        {...form}
        {...other}
        value={value}        
        onChange={this.handleChange}
      />

    )
  }
};

let DateField = createField(DateInput);

export { DateField, DateInput };
export default DateField;