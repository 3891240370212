import moment from 'moment';


export const dateError = (asset, date) => {
  let most = moment().format('HH:mm');
  let ma = moment().format('YYYY-MM-DD');
  let holnap = moment().add(1, 'd').format('YYYY-MM-DD');
  let du = '15:00';
  if (asset === 'SAJÁT') du = "15:30";

  if (!date) {
    return "Kötelező megadni a szállítás dátumát";
  }
  if (date < ma) {
    return "Nem lehet visszamenőleg leadni szállítást";
  }
  if (asset === 'SAJÁT') {
    if (date === ma) {
      return `Saját autóra legkorábban holnapi szállítást lehet kérni`;
    }
    if (date === holnap && most > du) {
      return `Saját autóra másnapi szállítást csak ${du}-ig lehet kérni`;
    }
  } else {
    if (most > du && date === ma) {
      return `${du} után nem lehet kérni aznapi szállítást`;
    }
  }
  return false;
}
