import { CalendarMonth, Cancel, Check, Drafts, Edit, Email, Inventory, LocalShipping, Money, Warning, AccessTime, Upload, Print } from "@mui/icons-material";
import { List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { EVENT } from "../../common";
import { dateToMoment, stampToMoment, format } from '../../utils';
import Loading from "../components/Loading";
import { useJSON } from "../data/fetch";


function EventList({ id, ...other }) {
  const list = useJSON({ url: '/delivery/events/' + id });
  if (!list) return <Loading />
  return <List sx={{ bgcolor: 'background.paper' }}>{list.map(e => <Event key={e.id} e={e} />)}</List>;
}

function Event({ e }) {

  let { primary, secondary, Icon } = formatEvent(e);
  return <ListItem>
    <ListItemIcon><Icon /></ListItemIcon>
    <ListItemText primary={primary} secondary={<div style={{ maxWidth: '280px' }}> {secondary} </div>} />
    <ListItemSecondaryAction sx={{ fontSize: 14 }}>{format.shortstamp(e.stamp)}<br />{e.username}</ListItemSecondaryAction>
  </ListItem>
}

function formatEvent(ev) {
  switch (ev.event) {
    case EVENT.DELIVERY_SCHEDULED:
      const { asset, date } = ev.payload.updates;
      return { Icon: CalendarMonth, primary: 'Átütemezés', secondary: `${asset} ${format.date(date)}` };
    case EVENT.PARCEL_NOTREADY:
      return { Icon: Drafts, primary: 'Csomag szétbontva' };
    case EVENT.PARCEL_READY:
      let cartons = 0;
      let palettes = 0;
      let palette_type = 'plt';
      let weight = 0;
      if (ev.payload && ev.payload.values) {
        cartons = ev.payload.values.cartons || 0;
        palettes = ev.payload.values.palettes || 0;
        palette_type = ev.payload.values.palette_type || 'plt';
        weight = ev.payload.values.weight || 0;
      }
      return { Icon: Inventory, primary: 'Összekészítve', secondary: `${cartons} krt, ${palettes} ${palette_type}, ${weight} kg` };
    case EVENT.PARCEL_COLLECTING:
      return { Icon: AccessTime, primary: 'Összekészítés alatt' }
    case EVENT.PARCEL_STARTING:
      return { Icon: Drafts, primary: 'Csomag szétbontva' };
    case EVENT.PARCEL_LOADED:
      return { Icon: LocalShipping, primary: 'Kiszállítás alatt' };
    case EVENT.PARCEL_DELIVERED:
      return { Icon: Check, primary: 'Kézbesítve' };
    case EVENT.PARCEL_REJECTED:
      return { Icon: Cancel, primary: 'Csomag nem lett átvéve' };
    case EVENT.DELIVERY_FAILED:
      return { Icon: Warning, primary: 'Meghiúsult kiszállítás', secondary: ev.payload.values.failreason };
    case EVENT.DELIVERY_CHANGED:
      return { Icon: Edit, primary: 'Adatok szerkesztve' };
    case EVENT.TRACKINGNR_SET:
      return { Icon: Edit, primary: ev.payload.updates.trackingnr? 'Trackingnr. megadva' : 'Trackingnr. törölve', secondary: ev.payload.updates.trackingnr };
    case EVENT.GLSLABEL_PREPARED:
      return { Icon: Upload, primary: 'GLS előkészítve', secondary: ev.payload.updates.trackingnr };
    case EVENT.GLSLABEL_PRINTED:
      return { Icon: Print, primary: 'GLS kinyomtatva', secondary: ev.payload.updates.trackingnr };
    case EVENT.INVOICE_READY:
      return { Icon: Money, primary: ev.payload.updates.invoice? 'Kiszámlázva' : 'Számlaszám törölve', secondary: `${ev.payload.updates.invoice} ${ev.payload.updates.cod? `utánvét: ${format.money(ev.payload.updates.cod)}` : ''}` };
  }
  return { Icon: Warning, primary: 'Ismeretlen esemény', secondary: ev.event };

}
export default EventList;