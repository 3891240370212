import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { postCommand, useJSON, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../../components/input';
import DeleteIcon from '@mui/icons-material/Delete';
import AddressField from '../../parcel/AddressField';
import AssetField from '../../parcel/AssetField';

const types = ['Kiszállítás', 'Minta darab', 'Alapanyag elhozatal', 'Visszáru', 'Beszerzés', 'Alvállalkozói kiszállítás', 'Alvállalkozótól vissza'];

export default function OrderDialog(props) {
  let { rec } = props.open;
  let refresh = useRefresh();
  const submit = async (values) => {
    values.address = `${values.country || ''} ${values.zip || ''} ${values.city || ''}, ${values.street || ''}${values.street2 ? ' ' + values.street2 : ''}`;
    await postCommand('delivery', COMMAND.UPDATE_PARCEL, { id: rec.ids, updates: values }); //ha át is ütemezte, akkor azt külön figyelnünk kell?
    refresh();
    props.close();
  }

  const validate = useCallback((rec) => {
    let ret = {};
    if (!rec) rec = {};
    if (!rec.date) ret.date = 'A dátumot meg kell adni';
    if (!rec.type) ret.type = 'Az irányt meg kell adni';

    if (!rec.partner) {
      ret.partner = 'Melyik partnerhez kapcsolódik?';
    }
    return ret;
  }, []);

  const del = async () => {
    await postCommand('delivery', COMMAND.DELETE_PARCEL, { id: rec.ids });
    refresh();
    props.close();
  }

  let { partner, asset, date, country, zip, city, street, type, smemo, contact, company } = rec.defval;
  return <Formik initialValues={{ partner, asset, date, country, zip, city, street, type, smemo, contact, company }} onSubmit={submit} validate={validate} {...props}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>{'Megrendelés szerkesztése'}</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ToggleField name="company" options={[
              { value: 'MACMA', label: 'MACMA' },
              { value: 'Easy Gifts', label: 'Easy Gifts' },
              { value: 'Spark', label: 'Spark' },
            ]} />
          </Grid>
          <Grid item xs={6} >
            <EnumField name="type" label="Szállítási irány" options={types.map(t => ({ value: t, label: t }))} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="partner" inputProps={{ style: { fontWeight: 'bold' } }} label="Partner" />
            <DateField name="date" label="Szállítás napja" />
            <AssetField />
          </Grid>
          <Grid item xs={12} md={4}>
            <AddressField />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="contact.name" label="Kontakt" />
            <TextField name="contact.tel" label="Telefon" />
            <TextField name="smemo" multiline label="Megjegyzés" />
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <IconButton onClick={del}><DeleteIcon /></IconButton><div style={{ flex: '1 0 0' }} />
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions>
    </form >
  )}
  </Formik>
}