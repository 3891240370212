import moment from 'moment';
import hu from 'moment/locale/hu.js';
import _ from 'lodash';
import { nanoid } from 'nanoid'

export const stampToMoment = str => moment(str, ['YYYY.MM.DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601])
export const dateTimeToMoment = (d, t) => moment(d + ' ' + (t || '00:00'), ['YYYY.MM.DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601])
export const dateToMoment = str => str.length === 10 ? moment(str, ['YYYY.MM.DD', 'YYYY-MM-DD']) : stampToMoment(str);
export const dateToStr = m => m ? m.format('YYYY.MM.DD') : null
export const stampToStr = m => m ? m.format('YYYY.MM.DD HH:mm') : null
export const dateToSql = m => m ? m.format('YYYY-MM-DD') : null
export const stampToSql = m => m ? m.format('YYYY-MM-DD HH:mm:ss') : null

export const stampFromMoment = (m) => m.format('YYYY-MM-DD HH:mm:ss');
export const dateFromMoment = (m) => m.format('YYYY-MM-DD');
export const now = () => stampFromMoment(moment());
export const nowdate = () => dateFromMoment(moment());
export const shortcal = {
  sameDay: '[Ma]',
  nextDay: '[Holnap]',
  nextWeek: 'dddd',
  lastDay: '[Tegnap]',
  lastWeek: '[Múlt] dddd',
  sameElse: 'L'
};
export const shortCalendar = m => m.calendar(shortcal);

export let defaultCurrency = 'HUF';

export const moneyToStr = (sum, currency, digits = 0) => {
  if (!sum) return '';
  currency = currency || defaultCurrency;
  if (sum % 1 !== 0) digits = 2;
  return Number(sum).toFixed(digits).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' ' + currency;
}

export const shortMoney = (sum, currency, digits = 0, prefix = '', postfix = '') => {
  sum = Number(sum);
  let si = [{ value: 1, symbol: "" }, { value: 1E3, symbol: "e" }, { value: 1E6, symbol: "M" }];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (sum >= si[i].value) {
      break;
    }
  }
  return prefix + (sum / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol + postfix + ' ' + currency || defaultCurrency;
}

export function ora(st) {
  return st && stampToMoment(st).format('dd HH:mm');
}

export function oraperc(min) {
  min = Math.round(min);
  return Math.floor(min / 60) + ':' + String(min % 60).padStart(2, '0') + 'h';
}

export const format = {
  shortNumber: (digits = 0, nullval = '', prefix = '', postfix = '') => (num) => {
    if (!num) return nullval;
    let si = [{ value: 1, symbol: "" }, { value: 1E3, symbol: "k" }, { value: 1E6, symbol: "M" }, { value: 1E9, symbol: "G" }, { value: 1E12, symbol: "T" }, { value: 1E15, symbol: "P" }, { value: 1E18, symbol: "E" }];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return prefix + (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol + postfix;
  },
  date: (date) => date && dateToStr(dateToMoment(date)),
  stamp: (date) => date && stampToStr(stampToMoment(date)),
  shortstamp: (date) => {
    if (!date) return date;
    let d = stampToMoment(date);
    if (moment().isSame(d, 'day')) {
      return 'ma ' + d.format('HH:mm');
    }
    return d.format('MM.DD. HH:mm');
  },

  moment: (format) => (val) => val && dateToMoment(val).format(format),
  ora,
  oraperc,
  fromNow: (val) => val && dateToMoment(val).fromNow(),
  toNow: (val) => val && dateToMoment(val).toNow(),
  calendar: (val) => val && dateToMoment(val).calendar(),
  percent: (val) => Math.round(Number(val) * 100) + '%',
  euro: (val) => moneyToStr(val, '€', 2),
  huf: (val) => moneyToStr(val, 'Ft', 0),
  money: (val, currency) => moneyToStr(val, currency),
  float: val => String(val).replace('.', ','),
  fixed: digits => val => String(val).includes('.') ? Number(val).toFixed(digits).replace('.', ',') : String(val),
  address: (v) => v && `${v.country || ''} ${v.zip || ''} ${v.city || ''}${v.street ? ',' : ''} ${v.street || ''}`
}


export function inside(bounds, p) {
  if (!bounds) return !!p;
  if (!p) return false;
  if (bounds[0][0] < p.plat) return false;
  if (bounds[0][1] < p.plon) return false;
  if (bounds[1][0] > p.plat) return false;
  if (bounds[1][1] > p.plon) return false;
  return true;
}

export function contrast(hexcolor) {
  if (!hexcolor || !hexcolor.length) return '#000000';
  if (hexcolor.slice(0, 1) === '#') hexcolor = hexcolor.slice(1);
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 170) ? '#000000' : '#ffffff';
}


export function gps_distance(lat1, lon1, lat2, lon2) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = Math.round((dist * 160.9344) + 0.5) / 100.0; // mert KM-ben kértük
    return dist;
  }
};

export function gps_eta(lat1, lon1, lat2, lon2) {
  let d = gps_distance(lat1, lon1, lat2, lon2);
  return moment().add(d * 1.2 / 65, 'h');
}

export function gps_total(points) {
  if (!points || points.length < 2) return 0;
  let len = points.length;
  let total = 0;
  for (let i = 1; i < len; i++) {
    total += gps_distance(points[i - 1][0], points[i - 1][1], points[i][0], points[i][1]);
  }
  return total;
};


export function nearest([lat, lon], points) {
  return points.reduce((acc, x) => {
    if (gps_distance(lat, lon, x[0], x[1]) < gps_distance(lat, lon, acc[0], acc[1])) {
      return x;
    }
    return acc;
  }, points[0]);
}

export function toMap(o, pkey) {
  return o.reduce((acc, r) => { acc[r[pkey]] = r; return acc; }, {})
}

export function deepCopy(o) {
  var out, v, key;
  out = Array.isArray(o) ? [] : {};
  for (key in o) {
    v = o[key];
    out[key] = (typeof v === "object" && v !== null) ? deepCopy(v) : v;
  }
  return out;
};


export function makeGPS(t) {
  let ret = { lat: 0.0, lon: 0.0, valid: false };
  if ((t.plat === 0.0 && t.plon === 0.0) || (t.plat === 1.0 && t.plon === 1.0)) {
  } else {
    ret.lat = t.plat;
    ret.lon = t.plon;
    ret.valid = true;
  }
  return ret;
}

export function distanceGPS(pos1, pos2) {
  if (!pos1 || !pos1.valid || !pos2 || !pos2.valid) return 0.0;
  return gps_distance(pos1.lat, pos1.lon, pos2.lat, pos2.lon);
};


export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function debounce_leading(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


//const numCollator = new Intl.Collator('hu-HU', { numeric: true, usage: 'sort' });
export const stringCollator = new Intl.Collator('hu-HU', { usage: 'sort' });
export const searchCollator = new Intl.Collator('hu-HU', { usage: 'search', sensitivity: 'base' });

export const ascNum = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
export const descNum = (a, b) => (a > b ? -1 : a < b ? 1 : 0);
export const ascString = (a, b) => a.localeCompare(b) || stringCollator.compare(a, b);
export const descString = (a, b) => 0 - stringCollator.compare(a, b);
export const asc = (a, b) => (!Number.isNaN(a) && !Number.isNaN(b)) ? ascNum(a, b) : ascString(0, b);
export const desc = (a, b) => 0 - asc(a, b);

export function normalizeTel(tel) {
  if (tel.startsWith('00')) tel = '+' + tel.substring(2);
  if (tel.startsWith('06')) tel = '+36' + tel.substring(2);
  return tel.replace(/[ \\/]/g, '');
}

export function validCoords(p) {
  if (!p) return false;
  let { plat, plon } = p;
  return plat > 30 && plat < 70 && plon < 40 && plon > -10;
}

export function calcBounds(points) {
  if (!points || !points.length) {
    return false;
  }
  return points.reduce((acc, p) => {
    if (!p) return acc;
    let { plat, plon, bbox } = p;

    if (!bbox) {
      if (!validCoords(p)) {
        return acc;
      }
      bbox = [plat, plon, plat, plon];
    }
    if (!acc[0]) {
      acc[0] = [bbox[0], bbox[1]];
    } else {
      acc[0][0] = Math.min(bbox[0], acc[0][0]);
      acc[0][1] = Math.min(bbox[1], acc[0][1]);
    }

    if (!acc[1]) {
      acc[1] = [bbox[2], bbox[3]];
    } else {
      acc[1][0] = Math.max(bbox[2], acc[1][0]);
      acc[1][1] = Math.max(bbox[3], acc[1][1]);
    }
    return acc;
  }, [false, false]);
}


export function copyFields(o, fi) {
  return fi.reduce((acc, f) => {
    acc[f] = o[f];
    return acc;
  }, {});
}


export const parseTime = str => {
  if (!str) return str;
  let v = str;
  v = v.replace(':', '');
  if (v.length === 4) {
    let h = parseInt(v.substring(0, 2));
    let m = parseInt(v.substring(2));
    if (h <= 24 && m <= 59) {
      return moment({ H: h, m: m });
    }
  }
  return null;
}

export const parseDate = str => {
  if (!str) return str;
  let y, m, d;
  let v = str.split(' ')[0].replace(/\./g, '');

  if (v.length === 4) {
    m = parseInt(v.substring(0, 2));
    d = parseInt(v.substring(2));
    if (m <= 12 && d <= 31) {
      let most = moment();
      y = most.year()
      if (most.month() - m > 5) {
        y += 1;
      }
    }

  }
  if (v.length === 6) {
    y = parseInt(v.substring(0, 2));
    m = parseInt(v.substring(2, 4));
    d = parseInt(v.substring(4));
  }

  if (v.length === 8) {
    y = parseInt(v.substring(0, 4));
    m = parseInt(v.substring(4, 6));
    d = parseInt(v.substring(6));
  }

  if (v.length === 10) {
    y = parseInt(v.substring(0, 4));
    m = parseInt(v.substring(4, 6));
    d = parseInt(v.substring(6));
  }

  if (y < 50) y += 2000;
  if (y < 100) y += 1900;
  //  console.log("parsed " + v, { y, m, d });
  if (y && m >= 1 && m <= 12 && d >= 1 && d <= 31) {
    //    console.log("valid date " + str);
    return moment({ y, M: m - 1, d });
  }
  return null;
}

export const parseStamp = str => {
  if (!str) return str;
  if (!str.includes(' ')) return null;
  let [d, t] = str.split(' ');
  d = parseDate(d);
  t = parseTime(t);
  //  console.log("parseStamp", d, t);
  if (d && t) {
    d.hour(t.hour());
    d.minute(t.minute());
    if (d.isValid()) return d;
  }
  return null;
}

export const parse = {
  stamp: parseStamp,
  date: parseDate,
  time: parseTime,
  int: parseInt,
  float: str => {
    if (typeof str === 'number') return str;
    return parseFloat(str.replace(/w/g, '').replace(',', '.'));
  },
  money: str => {
    if (typeof str === 'number') return str;
    return parseFloat(str.replace(/[^\d.,]/g, '').replace(',', '.'))
  }
}


export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export function normalizePort(val) {
  var port = parseInt(val, 10);

  if (isNaN(port)) {
    // named pipe
    return val;
  }

  if (port >= 0) {
    // port number
    return port;
  }

  return false;
}

export function validAttachment(a) {
  return a.length && a.fileName && a.contentDisposition !== 'inline' && !a.fileName.startsWith('image');
}


export const buildUrl = (url, p) => {
  if (!p) return url;
  let qs = Object.keys(p).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(p[k]));
  if (qs.length > 0) {
    url = url + "?" + qs.join('&');
  }
  return url;
}

export function publicId() {
  return nanoid() //=> "V1StGXR8_Z5jdHi6B-myT"
}

export function objectPath(fi, o) {
  let ret = fi ? [fi] : [];
  if (Array.isArray(o)) {
    for (let i = 0; i < o.length; i++) {
      ret = ret.concat(objectPath(`${fi}[${i}]`, o[i]));
    }
  } else if (o && typeof o === 'object') {
    let k = Object.keys(o);
    for (let i = 0; i < k.length; i++) {
      ret = ret.concat(objectPath(fi ? `${fi}.${k[i]}` : k[i], o[k[i]]));
    }
  }
  return ret;
}

export function getObjectDiff(old, act, skip) {
  let k = _.union(objectPath('', old), objectPath('', act));
  if (skip) skip.forEach(sk => {
    k = k.filter(p => !p.startsWith(sk))
  });
  let ret = [];
  for (let i = 0; i < k.length; i++) {
    if (!_.isEqual(_.get(old, k[i]), _.get(act, k[i]))) {
      ret.push(k[i]);
    }
  }

  return ret;
}



export function getDiffObject(oldVal, newVal) {
  if (_.isEqual(oldVal, newVal)) return false;

  if (!oldVal) oldVal = {};
  if (!newVal) newVal = {};
  const diff = Object.keys(oldVal).reduce((result, key) => {
    if (!newVal.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(oldVal[key], newVal[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(newVal));

  if (!diff.length) return false;

  return diff.reduce((result, key) => {
    result[key] = newVal[key];
    return result;
  }, {});
}