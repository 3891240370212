import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Button, Box, Paper, Grid, Typography, Link, Card, Menu, MenuItem, CardHeader, CardContent, Table, TableRow, TableCell, TableContainer, TableBody, ListItemIcon, ListItemText, Tooltip, Tabs, Tab } from "@mui/material";
import { useDialog } from '../components/Dialog'
import { dateToMoment, format, now, nowdate } from '../../utils';
import { Delete, DoneAll, Drafts, Edit, LocalShipping, Map, MoreVert, Print, Refresh, ShoppingCart, AccessTime } from '@mui/icons-material';
import { fetchJSON, postCommand, useJSON, useRefresh } from '../data/fetch';


export function TrackingButton({ d }) {
  let { trackingnr, asset } = d;
  if (!trackingnr) return null;
  if (asset === "GLS") return <Button variant="contained" href={`https://gls-group.eu/HU/hu/csomagkovetes.html?match=${trackingnr}`} target="glstracking">Nyomkövetés</Button>;
  return null;
}

export function GLSLabelButton({ d }) {
  let { gls } = d;
  if (!gls || !gls.pdf) return null;
  return <Button href={`/json/delivery/gls/labels/${d.id}`} target="glslabels">Nyomtatás</Button>;
}

export function GLSFunctions({ d }) {
  let refresh = useRefresh();
  let [loading, setLoading] = useState(false);
  let { trackingnr, gls, asset, statusenum } = d;
  let { setDialog } = useDialog();
  if ((asset !== "GLS" && asset !=="GLS Utánvét") || !statusenum || statusenum == 10){
    return null;
  }

  const printGLS = async () => {
    setLoading(true);
    let ret = await fetchJSON('POST', '/delivery/gls/print/' + d.id);
    if (ret.error) {
      alert(ret.error);
    } else {
//      console.log("PDF", '/json/delivery/gls/labels/' + d.id);
      window.open('/json/delivery/gls/labels/' + d.id, '_blank');
    }
    setLoading(false);
    refresh();
  }

  const prepareGLS = async () => {
    setLoading(true);
    let ret = await fetchJSON('POST', '/delivery/gls/prepare/' + d.id);
    if (ret.error) {
      alert(ret.error);
    }
    setLoading(false);
    refresh();
  }

  const setTrackingNr = () => {
    setDialog({ id: 'trackingnr', rec: d });
  }

//  console.log("GLSFunctions", d);

  if (trackingnr) {
    return <div>
      <Typography variant="body2">Trackingnr: {trackingnr}</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TrackingButton d={d} />
        <GLSLabelButton d={d} />
        <Button variant="outlined" disabled={loading} onClick={setTrackingNr}>Manuális</Button>
      </div>
    </div>
  }


  if (gls) {
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>Előkészítve: {gls.id.join(', ')}</div>
      <Button variant="outlined" disabled={loading} onClick={setTrackingNr}>Manuális</Button>
    </div>
  }

  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Button variant="contained" color="primary" disabled={loading} onClick={printGLS}>Nyomtatás</Button>
    <Button variant="outlined" color="secondary" disabled={loading} onClick={prepareGLS}>Előkészítés</Button>
    <Button variant="outlined" disabled={loading} onClick={setTrackingNr}>Manuális</Button>
  </div>
}