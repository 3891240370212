
let all = {};
function add(e){
  Object.values(e).map(v=>{
    Object.entries(v).map(([k,v])=>{
      if(all[k]) throw new Error('Duplicate event key '+k);
      if(Object.values(all).includes(v)) throw new Error(`Duplicate event value ${k} = ${v}`);
      all[k] = v;
    })
  })
  return e;
}

export function eventTxt(nr){
  let f = Object.entries(all).find(([k,v])=>v===nr);
  if(f) return f[0] || nr;
}

export function eventNr(txt){
  let f = Object.entries(all).find(([k,v])=>k===txt);
  if(f) return f[1];
}


export const parcel = add({
  card: {
    NEW_PARCEL: 1000,
    PARCEL_CHANGED: 1010,
    PARCEL_DELETED: 1020,
  },
  
  schedule: {
    DELIVERY_SCHEDULED: 1030,
    DELIVERY_CHANGED: 1040,
    DELIVERY_DELETED: 1050,
  },

  status:{
    PARCEL_READY: 1100,
    PARCEL_NOTREADY: 1101,
    PARCEL_LOADED: 1110,
    PARCEL_DELIVERED: 1120,
    PARCEL_REJECTED: 1130,
    PARCEL_COLLECTING : 1102,
    PARCEL_STARTING : 1103,
    DELIVERY_FAILED: 1140,    
    TRACKINGNR_SET: 1150,
    GLSLABEL_PREPARED: 1151,
    GLSLABEL_PRINTED: 1152,
    INVOICE_READY: 1160,
  },
  partner:{
    PARTNER_APPROVED: 1200,    
  }
});

export default all;



