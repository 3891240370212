let all = {};
function add(e) {
  Object.values(e).map(v => {
    Object.entries(v).map(([k, v]) => {
      if (all[k]) throw new Error('Duplicate command key ' + k);
      if (Object.values(all).includes(v)) throw new Error(`Duplicate command value ${k} = ${v}`);
      all[k] = v;
    })
  })
  return e;
}


export function commandTxt(nr) {
  let f = Object.entries(all).find(([k, v]) => v === nr);
 // console.log("commands:",f,all);
  if (f) return f[0] || nr;
}

export function commandNr(txt) {
  let f = Object.entries(all).find(([k, v]) => k === txt);
  if (f) return f[1];
}

export const parcel = add({
  status: {
    ADD_PARCEL: 150, 
    DELETE_PARCEL: 151, 
    CANCEL_PARCEL: 152, 
    UPDATE_PARCEL: 153, 
    SPLIT_PARCEL: 154,
  },

  email: {
  },

})


export const delivery = add({
  status : {
    SCHEDULE_DELIVERY : 160,
    UPDATE_ADDRESS : 161,
    DELETE_DELIVERY : 162,
    UPDATE_INVOICE: 163,
    UPDATE_TRACKINGNR: 164,
    DELEGATE_PACKING: 165,
    START_PACKING: 166,
    CHANGE_STATUS : 200,
  }
});


export default all;



