import { Protected, useAuth } from "../data/auth";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { dateFromMoment, dateToMoment, nowdate } from "../../utils";
import { fetchJSON, postCommand, useJSON, useRefresh } from "../data/fetch";
import { Logout, Menu, Add, Refresh } from "@mui/icons-material";
import { Button, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Field, Formik, useField } from 'formik';
import Loading from "../components/Loading";
import { ToggleField } from "../components/input/Select";


const PolcLabel = ({ unit, item }) => {
  return <div style={{ width: 54 * unit, textAlign: 'center', background: 'white', border: '1px solid black', marginTop: 2 }}>
    {item.Bin_Code}
  </div>
}


const CikkLabel = ({ unit, item }) => {
  return <div style={{ width: 106 * unit, textAlign: 'center', background: 'white', border: '1px solid black', marginTop: 2 }}>
    {item.Item_No}
  </div>
}


const PrintPaper = ({ labels, paperwidth }) => {

  let margo = 10;
  let width = 500;
  let unit = width / (paperwidth - 2 * margo);
  console.log("Paper", labels);

  return <div style={{
    display: 'flex', width, maxWidth: width, minWidth: width, padding: unit * margo, overflow: 'auto', flexWrap: 'wrap',
    background: 'white', border: '0px solid black',
    boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)'
  }}>
    {labels.map((it, i) => {
      switch (it.type) {
        case 'BIN': return <PolcLabel key={i} unit={unit} item={it} />
        case 'ITEM': return <CikkLabel key={i} unit={unit} item={it} />
        case 'BIN+ITEM': return <div key={i} style={{ display: 'flex' }}><PolcLabel unit={unit} item={it} /><CikkLabel unit={unit} item={it} /></div>
      }
    })}
  </div>

}

const PolcCimkeApp = () => {
  const db = useJSON({ url: '/polccimke/active' });
  const refresh = useRefresh();
  const [addDlg, showAddDlg] = useState(false);
  const [labels, setLabels] = useState([]);
  const [errors, setErrors] = useState([]);
  const [paperwidth, setPaperwidth] = useState(210);

  useEffect(() => {
    let tid = setTimeout(refresh, 10000);
    return () => clearTimeout(tid);
  }, [refresh])

  const save = async () => {
    let res = await fetchJSON('POST', '/polccimke/save', { labels, paper: paperwidth === 210 ? 'A4 portrait' : 'A4 landscape' });
    setLabels([]);
    setErrors([]);
    refresh();
    showAddDlg(false);
  }

  const cancel  = () =>{
    showAddDlg(false);
    setLabels([]);
    setErrors([]);
  }

  let content = null;
  if (addDlg) {
    const submit = async (values, opt) => {
      let { codelist, type, company } = values;
      console.log("OnSumbit", values, opt);
      if (codelist) {
        let codes = codelist.split(/[\s;,]+/g).filter(c => c);
        console.log("codes", codes);
        if (codes.length) {
          let res = await fetchJSON('POST', '/polccimke/init', { type, codes, company });
          console.log("add", res.ok);
          setLabels(labels.concat(res.ok));
          setErrors(res.error);
        }
      }
      opt.setFieldValue('codelist', '');
    }
    console.log("labels", labels);

    content = <Formik initialValues={{ codelist: '',company:'MACMA' }} onSubmit={submit}>{({ handleSubmit, setFieldValue, isSubmitting }) => (
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex' }}>
        <div style={{ margin: 16 }}>
          <h3>1. Polckódok vagy cikkszámok felsorolása</h3>
          <Field type="text" as="textarea" name="codelist" style={{ width: '100%', height: '80%' }}
            placeholder="Vegyesen is lehet cikkszámokat és polckódokat felsorolni. A kódokat elég szóközzel, vesszővel, pontosvesszővel elválasztva felsorolni, vagy új sorba is lehet írni." />
        </div>
        <div style={{ flexGrow: 0, margin: 16 }}>
          <h3>2. Cimkék hozzáadása</h3>
          <ToggleField name="company" options={[{value:'MACMA',label:'Macma'},{value:'Easy',label:'Easy'}]} /><br />
          <Button disabled={isSubmitting} type="submit" onClick={() => setFieldValue("type", "BIN")} variant="contained" style={{ width: '100%' }} >Polckód (54mm)  &gt;&gt;</Button> <br /><br />
          <Button disabled={isSubmitting} type="submit" onClick={() => setFieldValue("type", "ITEM")} variant="contained" style={{ width: '100%' }}>Cikk (106mm) &gt;&gt;</Button><br /><br />
          <Button disabled={isSubmitting} type="submit" onClick={() => setFieldValue("type", "BIN+ITEM")} variant="contained" style={{ width: '100%' }}>Polckód + Cikk (160mm) &gt;&gt;</Button>
        </div>
        <div style={{ flexGrow: 1, margin: 16, display: 'flex', flexDirection: 'column' }}>
          <h3>3. Ellenőrzés <Button endIcon={<Refresh />} onClick={() => setPaperwidth(paperwidth === 210 ? 298 : 210)}>
            {paperwidth === 210 ? "A4 álló" : "A4 fekvő"}
          </Button></h3>
          {errors && errors.length ? <div style={{ color: 'red' }}>{errors.map((e, i) => <div key={i}>{e}</div>)}</div> : null}
          <PrintPaper paperwidth={paperwidth} labels={labels} />
        </div>
        <div style={{ flexGrow: 1, margin: 16 }}>
          <h3>4. Mentés</h3>
          <Button disabled={isSubmitting} onClick={save} variant="contained" style={{ width: '50%' }}>Mentés</Button>
          <Button onClick={cancel}>Mégsem</Button>
        </div>
      </form>
    )}</Formik>
  } else {
    content = <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
        <Toolbar variant="dense">
          <IconButton onClick={() => refresh()} style={{ color: '#fff' }}><Refresh /></IconButton>
          <Typography variant="h6" noWrap component="div">
            Polccimke nyomtatványok
          </Typography>
          <Fab color="secondary" aria-label="add" style={{ position: 'fixed', right: 20, top: 20 }} onClick={() => { showAddDlg(true) }} >
            <Add />
          </Fab>
        </Toolbar>
      </AppBar>
      <Table style={{ marginTop: 60 }}>
        <TableHead>
          <TableRow>
            <TableCell>Létrehozta</TableCell>
            <TableCell></TableCell>
            <TableCell>Kinyomtatta</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {db && db.map(job => <TableRow key={job.id}>
            <TableCell>{job.username}</TableCell>
            <TableCell>{job.created}</TableCell>
            <TableCell>{job.printusername}</TableCell>
            <TableCell>{job.printed}</TableCell>
            <TableCell>
              {job.filename? 
              <Button onClick={() => {
                window.open(`/json/polccimke/${job.id}.pdf`, '_blank')
              }}>{job.printed ? 'Megnéz' : 'Nyomtat'}</Button>
              : <Loading />}
            </TableCell>
            <TableCell><Button onClick={async () => {
              await fetchJSON('DELETE', `/polccimke/${job.id}`);
              refresh();
            }}>Törlés</Button></TableCell>

          </TableRow>)}

        </TableBody>
      </Table>
    </Box>
  }



  return <Protected module="Polccimke felület" allowed={u => {
    return u;
  }}>
    <div style={{
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      overflow: 'hidden',
    }}>
      {content}
    </div>

  </Protected >
}


export default PolcCimkeApp;