import { useCallback, useMemo, useState } from 'react';
import { IconButton, Button, Box, Paper, Grid, Typography, Link, Card, Menu, MenuItem, CardHeader, CardContent, Table, Tab, Tabs, TableRow, TableCell, TableContainer, TableBody, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useDialog } from '../components/Dialog'
import { Header } from '../components/Header';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import { dateToMoment, format } from '../../utils';
import DataDisplay from '../components/DataDisplay'
import { Delete, DoneAll, Edit, FilePresent, LocalShipping, Map, MoreVert, Print, Refresh, ShoppingCart,  AlternateEmail  } from '@mui/icons-material';
import { postCommand, useJSON, useRefresh } from '../data/fetch';
import StatusIcon from "../parcel/StatusIcon";
import { COMMAND } from '../../common';
import EventList from '../eventlist/EventList';
import { TrackingButton } from '../planner/GLSDisplay';


let bgcolor = {
  "MACMA": "#EE6600",
  "Easy Gifts": "#6B508B",
  "Spark": "#ADD342"
}

function mailLink(d){
  let to = 'raktar_macma@macma.hu';
  if (d.company === "Spark"){
    let to = 'raktar_spark@spark.hu';
  }
  let subject = ((d.partner || '') + ' ' + (d.date|| ''));
  let ref = [];
  for (let i=0; i<d.parcel.length; i++){
    let p = d.parcel[i];
    ref.push(`${p.order} ${p.quantity} db  ${p.item} ${p.name}`);
  }
  let body = '';
  if (ref.length){
    body = ref.join("%0D%0A");
  }
  let link = `mailto:${to}?subject=${subject}&body=${body}`;
  console.log(link);
  return link;
}


export default function DeliveryPanel({ id, sidebar }) {
  const [menu, setMenu] = useState({ show: false, anchor: null });
  const [tab, setTab] = useState(0);

  const refresh = useRefresh();
  let { setDialog } = useDialog();
  const delivery = useJSON({ url: '/delivery/' + sidebar.open.delivery });
  if (!delivery) return <Panel header={<Header color='#ffffff' bgcolor='#ccc' caption='' title='Ilyen kártya már nincs' subtitel='' icon='' sidebar={sidebar} />} content={<Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}></Box>} />

  let d = delivery;
  let mailto = mailLink(d);

  let orders = delivery.parcel.reduce((acc, p) => {
    let id = p.order;
    if (acc[id]) {
      acc[id].item.push(p);
    } else {
      acc[id] = { raktar: p.raktar, rsz: p.rsz, order: p.order, type: p.type, id, item: [p] }
    }
    return acc;
  }, []);


  let op = [];
  op.push(<Tooltip key='mail' title='Levél a raktárnak'><Link component={IconButton} href={mailto}><AlternateEmail/></Link></Tooltip>);

  if (d.statusenum  >  0) {
    op.push(<Tooltip key="bill" title="Számla adatok"><IconButton onClick={() => setDialog({ id: 'bill', rec: d })}><FilePresent /></IconButton></Tooltip>);
  }

  return <Panel header={
    <Header color='#ffffff' bgcolor={bgcolor[d.company]}
      title={`${d.partner || ''}`}
      subtitle={<>{d.address} <Link component={IconButton} href={`https://google.com/maps/?q=${encodeURIComponent(d.address)}`} target="maps" ><Map /></Link></>}
      sidebar={sidebar}
      icon={<StatusIcon d={d} />}
      caption={d.status || 'Összekészítésre vár'}
      menu={<div>
        {op}
      </div>}
      tabs={
        <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
          <Tab label="Alapadatok" key="alap" />
          <Tab label="Történet" key="history" />
        </Tabs>
      }
    />
  }
    content={tab === 1 ? <EventList id={d.id} /> : <Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}>
      <Grid container>
        <DataDisplay label="Fuvarozó" data={d.asset} xs={4} />
        <DataDisplay label="Nyomtatvány" data={d.print} xs={4} />
        <DataDisplay label="Szállítás" data={d.date} xs={4} format={format.date} />
        <DataDisplay label="Karton" data={d.cartons} xs={4} />
        <DataDisplay label="Paletta" data={d.palettes} xs={4} />
        <DataDisplay label="Súly" data={d.weight} xs={4} />
        <DataDisplay label="Szállítási irány" data={d.type} xs={4} />
        <DataDisplay label="Számlaszám" data={d.invoice  || (d.nobill? 'Számla nélkül kiküldhető' : false)} xs={4} />
        <DataDisplay label="Utánvét összege" data={d.cod} xs={4} format={format.money} />
        {d.trackingnr && <DataDisplay xs={12} label="Nyomkövetés">
          <Typography variant="body2">Trackingnr: {d.trackingnr}</Typography>
          <TrackingButton d={d} />
        </DataDisplay>
        }
        <DataDisplay label="Sikertelen" data={d.failed} xs={4} format={format.fromNow} />
        <DataDisplay label="Ok" data={d.failreason} xs={4} />
        <DataDisplay label="Megjegyzés" data={d.failmemo} xs={4} />
      </Grid>
      {Object.values(orders).map(o => <Order key={o.id} o={o} setDialog={setDialog} />)}
    </Box>}
  />
}

function Order({ o, setDialog }) {
  const { memo, memo2, smemo, contact, salesperson, ext, sellto, created,statusenum } = o.item[0];
  console.log("Uzletkoto Order", o);
  return <Card style={{ marginBottom: 8 }}>
    <div style={{ padding: 8 }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="subtitle2" style={{ flexGrow: 1, padding: 4, textAlign: 'center' }}>{o.id || o.type || 'Raktárosi megbízás'}
        </Typography>
        <div>
          {!statusenum && <Edit style={{ fontSize: 16, color: '#999' }} onClick={
            () => {
              setDialog({ id: 'order', op: 'update', rec: { ids: o.item.map(i => i.id), defval: o.item[0] } });
            }
          } />}
        </div>        
      </div>
      <Grid container>
        {sellto && <DataDisplay xs={6} label='Partner' data={`${sellto.name || ''} (${sellto.no || ''})`} />}
        {salesperson && <DataDisplay xs={6} label='Üzletkötő' data={salesperson.name} />}
        {ext && <DataDisplay xs={6} label='Külső bizonylatszám' data={ext} />}
        {contact && <DataDisplay xs={6} label='Cím kontakt' data={`${contact.name} (${contact.tel || 'tel: ?'})`} />}
        <DataDisplay xs={6} label='Létrehozva' data={created} />
        <DataDisplay xs={12} label='Sofőr megjegyzés' data={smemo} />
        <DataDisplay xs={6} label='Megjegyzés' data={memo} />
        <DataDisplay xs={6} label='Webshop Megjegyzés' data={memo2} />
      </Grid>
    </div>
    {o.item.sort((a, b) => a.salesline - b.salesline).map((i, idx) => <Item key={i.salesline || idx} i={i} setDialog={setDialog} />)}
  </Card>
}

function Item({ i, setDialog }) {
  return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderTop: '1px solid #ccc', display: 'flex' }} key={i.salesline}>
    <div style={{ maxWidth: 50, minWidth: 50, textAlign: 'right', paddingRight: 8, whiteSpace: 'nowrap', }}>{i.quantity} db</div>
    <div style={{ maxWidth: 25, minWidth: 25, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {i.printed && <Print style={{ color: "#ccc", fontSize: 14, maringTop: -2 }} />}
    </div>
    <div style={{ maxWidth: 90, minWidth: 90, textAlign: 'left', paddingRight: 8, whiteSpace: 'nowrap', }}> {i.item}</div>
    <div style={{ flexGrow: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i.name}</div>
    {!i.statusenum && <div style={{ whiteSpace: 'nowrap', }}>
        <Edit style={{ fontSize: 16, color: '#999' }} onClick={() => { setDialog({ id: 'parcel', op: 'update', rec: i }); }} />
    </div>}
    {i.linememo && <div style={{ textAlign: 'right' }}>{i.linememo}</div>}

  </Typography>
}