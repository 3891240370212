import { Protected, useAuth } from "../data/auth";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COMMAND from '../../common/commands';
import { Button, Card, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField as MUITextField } from "@mui/material";
import Loading from "../components/Loading";
import { fetchJSON, useJSON, postCommand } from "../data/fetch";
import { FieldArray, Formik, Field } from "formik";
import { TextField } from "../components/input/Text";
import DateField from "../components/input/Date";
import { Delete, Receipt } from '@mui/icons-material';
import { ToggleField, EnumField } from "../components/input/Select";
import DataDisplay from "../components/DataDisplay";
import { useSnackbar } from 'notistack';
import assets from '../asset.json';
import { dateError } from "./validate";
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { backwards } from "../data/utils";


function AssetField(props) {
  const opt = assets.filter(a => !a.kocsi && a.id !== 'Nincs beosztva').map(a => ({ value: a.id, label: a.id }));
  return <EnumField name="asset" label="Szállítás módja" options={opt} {...props} />
}

/*

üzletkötő                         - raktáros
megrendeli a kiszálíltást
                              összekészít, megadja a paletta és csomag számokat, beoszt?
kiszámlázza a palettát és csomagokat, fuvart
                              nyomtat számlát, vagy átadásit vagy inkognitot
                              átadja a sofőrnek
                            
RSZ - szám a tételeknél
nyomtatvány: számlával - átadásival - ikognito
szállítási mód: Nincs beosztva, SAJÁT, GLS,...összes nem saját autó opció.


*/


const OrderForm = ({ order, close, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [defval, setDefval] = useState(false);

  const [templates, setTemplates] = useState([]);

  const select = (values, setFieldValue) => {
    let { partner, city, zip, country, street, contact, type } = values;
    setFieldValue('name', partner);
    setFieldValue('city', city);
    setFieldValue('zip', zip);
    setFieldValue('country', country);
    setFieldValue('street', street);
    setFieldValue('contact', contact);
    setFieldValue('type', type || 'Alvállalkozói kiszállítás');
  }

  const search = debounce(async (ev) => {
    const { value } = ev.target;
    if (value.length > 2) {
      let t = await fetchJSON('POST', '/delivery/templates', { term: value });
      setTemplates(t);
    } else {
      setTemplates([]);
    }
  }, 1000)


  useEffect(() => {
    console.log('fetchjson', order);
    fetchJSON('POST', '/bc/init',  {order}).then(x=>{
      x.type = 'Kiszállítás';
      x.datum = '';
      setDefval(x);
    });
  }, [])

//  const defval = useJSON({ url: '/bc/init', method: 'POST', order });
  if (!defval) return <div style={{ textAlign: 'center' }}>{order} betöltése... <Loading /></div>
  if (defval.salesperson && defval.salesperson.code !== user.salesperson) {
    //    return <div style={{ textAlign: 'center' }}>{order} üzletkötője nem {user.salesperson} <Button onClick={close} variant="outlined">Vissza</Button></div>
  }
  if (defval && defval.lines){
    defval.lines = defval.lines.filter(x=>x.type==='Item');
  }


  const submit = async ({ lines, partner, name, type, datum, ...other }) => {
    try {
      for (let i = 0; i < lines.length; i++) {
        let address = `${other.country || ''} ${other.zip || ''} ${other.city || ''}, ${other.street || ''}${other.street2 ? ' ' + other.street2 : ''}`;
        await postCommand('delivery', COMMAND.ADD_PARCEL, {
          ...lines[i],
          ...other,
          partner: name,
          sellto: partner,
          address,
          type,
          back: backwards({type})
        });
      }
      enqueueSnackbar('A szállítási igényt rögzítettük', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    }
    if (onSubmit) onSubmit();
    close();
  }

  const validate = (values) => {
    let lines=[];
    let {asset} = values;
    let rec = {};
    if (!values.type) rec.type  = 'Nem maradhat üresen';
    for(let i=0;i<values.lines.length;i++){
      let err = dateError(asset,values.lines[i].date);
      if(err) lines[i] = err;
    }

    if(lines.length) {
      rec.lines = lines;
    }
    return rec;
  }

  const calculateDatum = ({form, newValue}) => {
    let {values} = form;
    console.log('calculateDatum', values, newValue);
    if (newValue){
      values.datum = newValue;
      for (let i=0; i<values.lines.length; i++){
        values.lines[i].date = newValue;
      } 
      form.setValues(values);
    }
  };


  return <Box sx={{ bgcolor: 'background.paper', p: 4, margin: 'auto' }}>
    <Formik initialValues={defval} validate={validate} onSubmit={submit} validateOnChange={true} validateOnBlur={false}>{({ isSubmitting, errors, values, handleSubmit, setFieldValue }) => (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={4} style={{ alignItems: 'flex-start' }}>
          <Grid container item xs={12} md={4} spacing={0}>
            <Grid item xs={12}><Typography variant="h6">{values.order}</Typography></Grid>
            <DataDisplay xs={6} label="Partner" data={`${values.partner.name} (${values.partner.no})`} />
            <DataDisplay xs={6} label="Külső bizonylatszám" data={values.ext} />
            <DataDisplay xs={6} label="Kontakt" data={values.partner.contact.name} />
            <DataDisplay xs={6} label="Telefon" data={values.partner.contact.tel} emptyWarning />
            <DataDisplay xs={6} label="Üzletkötő" data={values.salesperson.name} />
            <DataDisplay xs={6} label="Email" data={values.salesperson.email} />
            <DataDisplay xs={12} label="Megjegyzés" data={values.memo} />
            <DataDisplay xs={12} label="Webshop Megjegyzés" data={values.memo2} />
            <Grid item xs={12}><TextField name="name" label="Címzett" /></Grid>
            <Grid item xs={2}><TextField name="country" label="Ország" /></Grid>
            <Grid item xs={4}><TextField name="zip" label="Irsz" /></Grid>
            <Grid item xs={6}><TextField name="city" label="Város" /></Grid>
            <Grid item xs={12}><TextField name="street" label="Utca/házszám" /></Grid>
            <Grid item xs={6}><TextField name="contact.name" label="Kontakt" /></Grid>
            <Grid item xs={6}><TextField name="contact.tel" label="Telefon" /></Grid>
            <Grid item xs={4}><EnumField label="Nyomtatvány" name="print" options={[{ value: 'Szállítólevél', label: 'Szállítólevél' }, { value: 'Átadási', label: 'Átadási' }, { value: 'Inkognitó', label: 'Inkognitó' }]} /></Grid>
            <Grid item xs={4}><AssetField label="Szállítás módja" name="asset" /></Grid>
            <Grid item xs={4}><DateField label="Kiszállítás" name="datum" calculate={calculateDatum}/></Grid>
            <Grid item xs={6}><TextField name="smemo" multiline label="Megjegyzés a szállításhoz" /></Grid>
            <Grid item xs={6}><EnumField label="Szállítási irány" name="type" options={[{ value: 'Kiszállítás', label: 'Kiszállítás' }, { value: 'Alvállalkozói kiszállítás', label: 'Alvállalkozói kiszállítás' }, { value: 'Alvállalkozótól vissza', label: 'Alvállalkozótól vissza' }]} notnull /></Grid>
            <Grid item xs={12}><Template search={search} select={select} templates={templates} setFieldValue={setFieldValue}/></Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Cikkszám</TableCell>
                  <TableCell>RSZ / Raktár</TableCell>
                  <TableCell>Megnevezés</TableCell>
                  <TableCell>Megjegyzés</TableCell>
                  <TableCell>Mennyiség / Kiszállított</TableCell>
                  <TableCell>Kiszállítandó</TableCell>
                  <TableCell>Kiszállítás</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name="lines" render={({ form, remove, push }) => {
                  const { lines } = values;
                  return lines.map((l, i) => <SalesLine key={l.salesline} l={l} index={i} remove={remove} error={errors && errors.lines && errors.lines[i]}/>)
                }} />

              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Box style={{ marginTop: 20, display: 'flex', alignItems: 'space-between', justifyContent: 'center', gap: 24, alignItems: 'end' }}>
          <div><Button onClick={close} variant="outlined">Mégse</Button></div>
          <div>
            <Button type="submit" color="primary" variant="contained" disabled={Object.keys(errors).length || !values.lines.length || isSubmitting}>Szállítás megrendelése</Button>
          </div>
        </Box>
      </form>
    )}</Formik>
  </Box>

}


function Template({search, select, templates, setFieldValue}){
  const height = 190;
  let ret = 
  <Box sx={{ p: 2 }}>
  <MUITextField fullWidth variant="standard" label="Sablon" autoFocus onChange={search} />
  <div style={{ height, overflow: 'auto' }}>
    <Table size="small" >
      <TableBody>
        {templates.map(t => <TableRow hover key={t.id} onClick={() => {select(t, setFieldValue)}}>
          <TableCell>{t.partner}</TableCell>
          <TableCell>{t.type}</TableCell>
          <TableCell>{t.city} {t.street}</TableCell>
        </TableRow>)
        }
      </TableBody>
    </Table>
  </div>
</Box>
return ret;
};

function SalesLine({ index, remove, error, l: { salesline,memo, item, name, rsz, qtotal, qshipped, raktar,printed } }) {
  let r = <TableRow key="l" style={{background: error? '#fcc' : 'inherit'}}>
    <TableCell>{item} {printed? " / PRINT" :""}</TableCell>
    <TableCell>{rsz || <span style={{ color: 'red' }}>NINCS</span>} / {raktar}</TableCell>
    <TableCell>{name}</TableCell>
    <TableCell><TextField name={`lines.${index}.linememo`} margin="none" /></TableCell>
    <TableCell>{qtotal} / {qshipped}</TableCell>
    <TableCell><TextField name={`lines.${index}.quantity`} type="number" margin="none" style={{ width: 65 }} inputProps={{ style: { textAlign: 'right' } }} /></TableCell>
    <TableCell><DateField name={`lines.${index}.date`} margin="none" InputProps={{ style: { width: 118 } }} /></TableCell>
    <TableCell><IconButton onClick={() => {
      remove(index);
    }}><Delete /></IconButton>
    </TableCell>
  </TableRow>  
  if(error){
    return [r,<TableRow key="error" style={{background:'#fcc'}}>
    <TableCell colSpan={8} align="right">{error}</TableCell>
  </TableRow> ]
  }

  return r;
}

export default OrderForm;