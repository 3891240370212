import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { postCommand, useJSON, useRefresh } from '../data/fetch';
import { COMMAND } from '../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../components/input';
import { now } from '../../utils';

const okok = ['Nincs a címen', 'Kontakt nem elérhető','Híbás cím'].map(t=>({value:t,label:t}));

export default function FailedDialog(props) {
  let { rec } = props.open;
  let refresh = useRefresh();
  const submit = async (values) => {
    values.failed = now();
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: rec.id, status: 'FAILED', values });
    refresh();
    props.close();
  }

  return <Formik initialValues={{}} onSubmit={submit}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>Nem lehet kézbesíteni</DialogTitle>
      <Grid container sx={{ p: 2 }} columnSpacing={2}>
        <Grid item xs={12} md={4}><EnumField name="failreason" label="Miért nem sikerült kézbesíteni?" options={okok}/></Grid>
        <Grid item xs={12} md={4}><TextField name="failmemo" label="Megjegyzés" /></Grid>
      </Grid>
      <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Jelzés küldése</Button>
      </DialogActions>
    </form >
  )}
  </Formik>
}