import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { postCommand, useJSON, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField, SwitchField } from '../../components/input';


export default function BillDialog(props) {
  let { rec } = props.open;
  let refresh = useRefresh();
  const submit = async (updates) => {
    await postCommand('delivery', COMMAND.UPDATE_INVOICE, { delivery: rec.id, updates });
    refresh();
    props.close();
  }

  const validate = ({invoice,cod,nobill})=>{
    let ret = {};
    if(nobill && cod) ret.cod = 'Számla nélkül nem lehet utánvétet kérni';
    return ret;
  }

  return <Formik initialValues={{invoice:rec.invoice, cod:rec.cod, nobill: rec.nobill }} onSubmit={submit} validate={validate}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>Dokumentum kiszállításhoz</DialogTitle>
      <Grid container sx={{ p: 2 }} columnSpacing={2}>
        <Grid item xs={6}><TextField name="invoice" label="Számlaszám vagy szállítólevél száma" /></Grid>
        <Grid item xs={6}><TextField type="number" name="cod" label="Utánvét összege" /></Grid>
        <Grid item xs={12}><SwitchField name="nobill" label="Nem készült a küldeményhez dokumentum" /></Grid>
      </Grid>
      <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions>
    </form >
  )}
  </Formik>
}