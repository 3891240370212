import { createContext, useCallback, useContext, useMemo } from "react";

const MetaCtx = createContext({});
export const useFormMeta = () => useContext(MetaCtx);
export const FormMeta = ({meta, children}) => <MetaCtx.Provider value={meta}>{children}</MetaCtx.Provider>

//A prefix és a calculate az rekurzív!

const PrefixCtx = createContext('');
export const useFieldPrefix = (name) => {
  let p = useContext(PrefixCtx);
  if (p) return p + '.' + name;
  return name;
}

export const FieldPrefix = ({ prefix, children }) =>{
  prefix = useFieldPrefix(prefix);
  return <PrefixCtx.Provider value={prefix}>{children}</PrefixCtx.Provider>
}


const CalculateCtx = createContext();
export const useCalculate = (func) => {
  let p = useContext(CalculateCtx);
  return useMemo(()=>{
    if(!func || !p) return func;
    return (...args) => {
      func(args);
      p(args);
    };  
  },[func,p])
}

export const Calculate = ({ calculate, children }) =>{
  console.log("Calculate CTX",calculate);
  calculate = useCalculate(calculate);
  return <CalculateCtx.Provider value={calculate}>{children}</CalculateCtx.Provider>
}
