import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import moment from 'moment';
import { dateFromMoment, nowdate } from '../../utils';
import { CalendarViewDay, DateRange, Email, EventBusy, HourglassEmpty, ListAlt, LocalShipping, Today, Warning } from '@mui/icons-material';
import { withStyles } from '@mui/styles';


let DateItem = ({ label, Icon, onClick, style, isActive, classes }) => {
  return <ListItem disablePadding onClick={onClick} style={style} selected={isActive} >
    <ListItemButton>
      <ListItemIcon>
        <Icon style={style} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  </ListItem>
}


export default function PlannerMenu({ days, assets, setDate, setAsset, active, setArchive }) {
  let ma = dateFromMoment(moment());
  const isActive = ({ date, asset }) => {
    console.log("isActive", active, date, asset);
    if (active && active.date && active.date.date === date) return true;
    if (active && active.asset && active.asset === asset) return true;
    return false;
  }

  return <Box sx={{ overflow: 'auto' }}>
    <List>
      {days.map(({ label, date }, index) => <DateItem key={date} label={label} Icon={date < ma ? Warning : DateRange} onClick={() => setDate({ label, date })} style={{ color: date < ma ? 'red' : 'inherit' }} isActive={isActive({ date })} />)}
    </List>
    <Divider />
    <List>
      {assets.map(({ id, color }) => {
        return (
          <ListItem key={id || 'Nincs'} disablePadding onClick={() => setAsset(id)} selected={isActive({ asset: id })}>
            <ListItemButton>
              <ListItemIcon>
                {color === '#999' ? <HourglassEmpty /> : color === '#df6' ? <LocalShipping /> : <Email />}
              </ListItemIcon>
              <ListItemText primary={id} />
            </ListItemButton>
          </ListItem>
        )
      })}
      <Divider />
      <ListItem disablePadding onClick={setArchive} selected={active.archive}>
        <ListItemButton>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText primary="Archívum" />
        </ListItemButton>
      </ListItem>
    </List>
  </Box>

}