import { Check, Drafts, Email, Inventory, LocalShipping, Warning, AccessTime } from "@mui/icons-material";
import { dateToMoment } from '../../utils';


const StatusIcon = ({ d, ...other }) => {
  switch (d.statusenum) {
    case 0: return <Drafts {...other}/>;
    case 1: return <Inventory  {...other} />;
    case 2: return d.failed? <Warning  {...other}/> : <LocalShipping {...other} />;
    case 3: return <Check  {...other} />;
    case 10 : return <AccessTime {...other} />;
  }
  return '?';
}

export default StatusIcon;