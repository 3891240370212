import { Protected } from "../data/auth";
import "./styles.css";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import { Dialog, registerDialog, useDialog } from '../components/Dialog';
import Archive from "./Archive";

//registerPanel({ id: 'parcel', Component: ParcelPanel })


const ArchiveApp = () => {
  return <Protected module="Vezetői felület">
    <div style={{
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      overflow: 'hidden',
    }}>

      <Archive />
      <Sidebar />
      <Dialog />
    </div>
  </Protected >
}


export default ArchiveApp;