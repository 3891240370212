import React, { useMemo } from 'react';
import { Input, TextField } from '@mui/material';
import MUIAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { CircularProgress, Button, ButtonGroup } from '@mui/material';
import { useJSON } from '../../data/fetch';
import { createField } from './createField';
import { Edit } from '@mui/icons-material';

export const Select = (props) => {
  let { options } = props;
  if (!options || !options.length) options = [];
  let { field, form, calculate, label, ...other } = props;
  if (!field.value) field.value = '';

  const onChange = (ev) => {
    const oldValue = field.value;
    field.onChange(ev); //tovább adjuk
    if (calculate) calculate({ oldValue, newValue: ev.target.value, field, form })
  }

  /*
  const onBlur = (ev) => {
    field.onBlur(ev); //tovább adjuk
    if (calculate) calculate({ oldValue:ev.target.value, newValue: ev.target.value, field, form })
  }*/
  console.log(label,props,options);

  const error = form.errors[field.name];
  //  console.log("SELECT",{field,other});
  return <FormControl fullWidth margin="normal" variant="standard">
    <InputLabel>{label}</InputLabel>
    <MUISelect
      {...field}
      {...other}
      error={!!error}
      //  onBlur={onBlur}
      onChange={onChange}
      helperText={error}
      InputLabelProps={other.InputLabelProps || {}}>
      {!props.notnull && <MenuItem value="">
        <em> - </em>
      </MenuItem>}
      {props.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
    </MUISelect>
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
}

export const FetchSelect = (props) => {
  let { url, format, ...other } = props;
  let val = useJSON({ url });
  other.options = useMemo(() => {
    if (!val) return [];
    if (Array.isArray(val) && format) {
      return val.map(format);
    } else {
      return Object.entries(val).map(format);
    }
  })

  return <Select {...other} />;
}

export const ToggleGroup = (props) => {
  let { field, form, calculate, label, options, ...other } = props;
  //  console.log('ToggleButtons', options);
  const click = v => {
    const oldValue = field.value;
    field.value = oldValue === v ? 0 : v;
    form.setFieldValue(field.name, field.value);
    if (calculate) calculate({ oldValue, newValue: field.value, field, form })
  }

  const error = form.errors[field.name];
  return <FormControl fullWidth margin="normal" variant="standard" style={{ marginTop: 16 }} {...other} >
    <ButtonGroup>{
      options.map(o => {
        return <Button
          key={o.value}
          onClick={() => click(o.value)}
          size="small"
          variant={field.value === o.value ? 'contained' : 'outlined'}
        >
          {o.label}
        </Button>
      })
    }</ButtonGroup>
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
}


export const EnumField = createField(Select);
export const FetchEnumField = createField(FetchSelect);
export const ToggleField = createField(ToggleGroup);
export function arrayToOption(ar, hasnull = true) {
  let opt = ar.map(k => ({ value: k, label: k }));
  if (hasnull) opt.unshift({ value: '', label: '' });
  return opt;
}

const createFilter = (label, displayfield, searchfield, newPrefix) => (options, params) => {
  const filter = createFilterOptions({ limit: 20, stringify: p => p[displayfield] || p[searchfield] });
  const filtered = filter(options, params);
  //  console.log("auto filter", { options, params, filtered });
  if (params.inputValue !== '') {
    const v = params.inputValue.toLowerCase();
    if (filtered.filter(o => v === params.getOptionLabel(o).toLowerCase()).length) {
      //      console.log("Pont ugyanazt gépelte be");
    } else {
      const prefix = typeof newPrefix === "string" ? newPrefix : `Új ${label}: `;
      const v = params.inputValue.replace(prefix, '');
      filtered.push({
        id: v,
        [displayfield]: prefix + v,
        newItem: true
      });
    }
  }
  return filtered;
};

export const Autocomplete = (props) => {
  let { label, options, field, form, displayfield, searchfield, multiple, create,
    getOptionLabel, filterOptions, filterSelectedOptions, calculate,
    newPrefix, handleNew, handleEdit, noOptionsText, ...other } = props;

  const handleChange = (_, val) => {
    //    console.log("auto change", val);
    if (val && val.newItem) {
      handleNew(val.id, form);
      return;
    }
    if (Array.isArray(val)) {
      form.setFieldValue(field.name, val.map(v => v.inputValue || v));
      if (calculate) {
        calculate({ oldValue: field.value, newValue: val, field, form });
      }
    } else {
      form.setFieldValue(field.name, val);
      if (calculate) calculate({ oldValue: field.value, newValue: val, field, form });
    }
  }


  if (!displayfield) displayfield = 'label';
  if (!searchfield) searchfield = 'searchstr';

  if (create && !filterOptions) {
    filterOptions = createFilter(label, displayfield, searchfield, newPrefix);
  }

  if (!getOptionLabel) {
    getOptionLabel = (option) => {
      //      console.log("getOptionsLabel", option[displayfield]);
      if (!option) return '';
      return (option[displayfield] || option.id || option || '');
    }
  }
  //  console.log("Autocomplete",field.name,field.value);

  return <MUIAutocomplete
    multiple={multiple}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    autoHighlight
    freeSolo={create}
    value={field.value}
    // autoSelect={true} //ilyet nem szabad!!
    onChange={handleChange}
    options={options || []}
    filterOptions={filterOptions}
    noOptionsText={noOptionsText || `Nincs ilyen ${label}`}
    filterSelectedOptions={filterSelectedOptions}
    getOptionLabel={getOptionLabel}
    getOptionSelected={(option, value) => option.id === (value.id || value)}

    {...other}

    renderInput={(params) => {
      //      console.log("autocomplete InputProps", {label, value:params.inputProps.value, params});
      if (params.inputProps.value === '[object Object]') params.inputProps.value = '';
      return (<>
        <TextField
          variant="standard"
          margin="normal"
          {...params}
          value={params.inputProps.value}
          label={label}
          error={form.errors[field.name]}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
                {!options ? <CircularProgress color="inherit" size={20} /> : null}
                {(handleEdit && field.value) ? <Edit style={{ color: '#bbb', cursor: 'pointer' }} onClick={() => handleEdit(field.value, form)} /> : null}
              </React.Fragment>
            ),

          }}
        />
        {form.errors && form.errors[field.name] ?
          <FormHelperText error id="component-helper-text">
            {form.errors[field.name]}
          </FormHelperText>
          :
          <FormHelperText id="component-helper-text">
            {props.info ? props.info(field.value) : ''}
          </FormHelperText>}
      </>
      )
    }
    }
  />
}

export const AutocompleteField = createField(Autocomplete);


export const SuggestInput = (props) => {
  let { label, field, form, options, calculate, ...other } = props;
  if (!options || !options.length) options = [];

  //  console.log("SuggestField", { label, options, field });

  const handleChange = (ev, val) => {
    //    console.log("suggest Changed: ", { ev, val });
    form.setFieldValue(field.name, val);
    if (calculate) calculate({ oldValue: field.value, newValue: val && (val.id || val), field, form });
  }

  const error = form.errors[field.name];

  return <FormControl fullWidth>
    <MUIAutocomplete
      freeSolo
      value={field.value}
      onChange={handleChange}
      onInputChange={handleChange}
      options={options}
      renderInput={(params) => {
        //        console.log("suggest renderInput", params);
        return (
          <TextField fullWidth margin="normal" variant="standard" {...params} label={label} placeholder={other.placeholder} InputLabelProps={other.InputLabelProps || {}} />
        )
      }}
      {...other}
    />
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
}

export const SuggestField = createField(SuggestInput);

/*
export const ReferenceInput = (props) => {
  console.log("Referencia", props);
  //  const {fkey} = useFieldMeta(props.name);
  let { label, fkey, name, field, form, multiple, create, filterOptions, filterSelectedOptions, filter } = props;
  let url = { url: `options/${fkey.table || field.name || name}`};
  if(filter) url.filter = filter;
  let options = useJSON(url);
  let META = useMeta();
  let reftable = META[fkey.table || field.name];
  let labelField = Object.entries(reftable).filter(([k, v]) => v.optlabel)[0][0] || (fkey.calc && fkey.calc[0]) || 'label';

  const validateInput = (val) => {
    if (val && val.inputValue) {
      val = { id: val.inputValue };
      val[labelField] = val.inputValue;
    }
    return val;
  }

  const handleChange = (ev, val) => {
    console.log("handle Referencia change: ", val);
    if (Array.isArray(val)) {
      form.setFieldValue(field.name, val.map(validateInput));
    } else {
      form.setFieldValue(field.name, validateInput(val));
    }
  }

  if (create && !filterOptions) {
    filterOptions = createFilter(label);
  }
  const error = form.errors[field.name];

  return <FormControl fullWidth margin="normal">
    {options &&
      <MUIAutocomplete
        label={label}
        multiple={multiple}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo={create}
        value={field.value}
        onChange={handleChange}
        options={options}
        noOptionsText={`Nincs ilyen ${label}`}
        filterOptions={filterOptions}
        filterSelectedOptions={filterSelectedOptions}
        getOptionLabel={(option) => (option[labelField] || option.label || (option.id && String(option.id)) || option || '')}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} placeholder={`${label} választása`} />
        )}
      />}
    {error && <FormHelperText>{error}</FormHelperText>}
    {!options && <CircularProgress />}
  </FormControl>
}




export const SuggestField = (props) => {
  let { label, field, form } = props;
  let { rec, meta } = useRecordMeta();
  let options = useJSON({ url: `suggest/${rec}/${field.name}` });
  if (!options || !options.length) options = [];

  console.log("SuggestField", { label, options, field });

  const handleChange = (ev, val) => {
    console.log("suggest Changed: ", { ev, val });
    form.setFieldValue(field.name, val);
  }

  const error = form.errors[field.name];

  return <FormControl fullWidth margin="normal">
    <MUIAutocomplete
      freeSolo
      value={field.value}
      onChange={handleChange}
      onInputChange={handleChange}
      options={options}
      renderInput={(params) => {
        console.log("suggest renderInput", params);
        return (
          <TextField {...params} label={label} />
        )
      }}
    />
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
}
*/