import { Protected } from "../data/auth";
import "./styles.css";
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { dateFromMoment, dateToMoment, format, nowdate } from "../../utils";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import { Dialog, registerDialog, useDialog } from '../components/Dialog';
import { fetchJSON, postCommand, useJSON, useRefresh } from "../data/fetch";
import { lightBlue, lightGreen, red, teal } from '@mui/material/colors';
import assets from '../asset.json';
import { SearchInput } from "../components/Search";
import { Logout, Menu, Add, Refresh } from "@mui/icons-material";
import COMMANDS from '../../common/commands';
import { Button, IconButton } from "@mui/material";
import { Formik, useField } from 'formik';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../components/input';
import DataTable from "../components/DataTable";
import { C } from "../../server/sql/types";
import StatusIcon from "../parcel/StatusIcon";

const drawerWidth = 240;


//registerPanel({ id: 'parcel', Component: ParcelPanel })


const Archive = ({ term }) => {
  const { setSidebar } = useSidebar();
  const from = moment().subtract(7, 'd');
  const [filter, setFilter] = useState({ from, to: moment() });
  const [data, setData] = useState([]);
  const refresh = useRefresh();

  useEffect(() => {
    fetchJSON('POST', '/delivery/salesperson/archive', { ...filter, term }).then(setData);
  }, [filter, term, refresh])

  const submit = (values) => {
    setFilter({ ...filter, ...values });
  }

  const onRowClick = useCallback(({rowData})=>{
    setSidebar({ delivery: rowData.id });
  },[setSidebar]);

//  console.log("data",data);

  return <Box sx={{ display: 'flex',flexGrow:100,bgcolor:'background.paper' }}>
    <Box
      sx={{
        width: drawerWidth,
        flexShrink: 0,
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ p: 2 }}>
        <Formik initialValues={filter} onSubmit={submit}>{({ handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} autocomplete="off">
            <DateField name="from" label="Szállítás napja" />
            <DateField name="to" label="" />
            <TextField name="partner" label="Partner" />
            <TextField name="order" label="Bizonylat" />
            <TextField name="item" label="Cikk" />
            <Button variant="contained" type="submit">Keresés</Button>
          </form>
        )}
        </Formik>
      </Box>
    </Box>
    <Box style={{
      background: '#fff',
      flexGrow: 1,
      paddingTop: 50,
      height: '100vh'
    }}>
      <DataTable
        header={false}
        list={data}
        //  table="parcel"
        onRowClick={onRowClick}
        columns={[
          { width: 50, label: '', dataKey: 'statusenum', format: (col,val)=><StatusIcon d={val}/> },
          { width: 100, label: 'Dátum', dataKey: 'date', format: format.date },
          { width: 180, label: 'Partner', dataKey: 'partner' },
          { width: 300, label: 'Tétel', dataKey: 'parcel', format: (col, val) => <>
            {val.parcel.length} tétel 
            {val.cartons?` | ${val.cartons} karton` : ''}
            {val.palettes?` | ${val.palettes} paletta` : ''}
            {val.weight?` | ${val.weight} kg` : ''}
            </> 
          },
          { width: 100, label: 'Kézbesítő', dataKey: 'asset'},
          { width: 180, label: 'Kézbesítve', dataKey: 'delivered', format: format.stamp},
        ]}
      />
    </Box>
  </Box>
}


export default Archive;