import {useFieldPrefix,useCalculate} from '../form/Context';
import {FastField, Field} from 'formik';

export const createField = Editor => ({ fast, name, calculate, ...props }) => {
  name = useFieldPrefix(name);
  calculate = useCalculate(calculate);
  let F = fast ? FastField : Field;
  return <F
    component={Editor}
    margin="normal"
    fullWidth
    calculate={calculate}
    name={name}
    InputLabelProps={{shrink:true}}
    {...props}

  />
}
