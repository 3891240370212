import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import { AutoSizer, Column, Table } from 'react-virtualized';
import TableSortLabel from '@mui/material/TableSortLabel';
import Loading from './Loading';
import { Link } from './Link';
import { asc, desc, stampToMoment } from '../../utils.js';
import { Button, Dialog, DialogActions, DialogContent, LinearProgress, Grid, Chip } from '@mui/material';
import { Filter, FilterList } from '@mui/icons-material';
import { useMeta, useFieldMeta } from '../data/meta';
import { TextField, DateField, StampField, Select } from './input';
import { Formik, FastField as FormikField } from 'formik';
import _ from 'lodash';

export const defaultComparator = (order, orderBy, sortVal) => {
  if (sortVal) {
    return order === 'desc'
      ? (a, b) => {
        return desc(sortVal(_.get(a, orderBy), a), sortVal(_.get(b, orderBy), b)) || asc(a.id, b.id)
      }
      : (a, b) => asc(sortVal(_.get(a, orderBy), a), sortVal(_.get(b, orderBy), b)) || asc(a.id, b.id)
  }

  return order === 'desc'
    ? (a, b) => desc(_.get(a, orderBy), _.get(b, orderBy)) || asc(a.id, b.id)
    : (a, b) => asc(_.get(a, orderBy), _.get(b, orderBy)) || asc(a.id, b.id)
}


const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  headerCell: {
    //  textAlign:'left'
  },
  noClick: {
    cursor: 'initial',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});


const noMenu = { rec: null, x: null, y: null };


class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  state = { menu: noMenu };

  onContextMenu = rec => event => {
    //    console.log("onContextMenu", { rec, event });
    event.preventDefault();
    this.setState({ menu: { rec, x: event.clientX - 2, y: event.clientY - 4 } });
  };

  closeContextMenu = () => {
    this.setState({ menu: noMenu });
  }


  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;
    const rcls = this.props.getRowClassName && this.props.getRowClassName(index);

    return clsx(rcls, classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex, rowData }) => {
    const { columns, classes, rowHeight, onRowClick, table } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
        onContextMenu={this.onContextMenu(rowData)}
      >
        {columns[columnIndex].format ? columns[columnIndex].format(cellData, rowData, columns[columnIndex], table) : cellData}
      </TableCell>
    );
  };

  sortHandler = (dataKey) => (event) => this.props.requestSort && this.props.requestSort(dataKey, event);

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes, order, orderBy } = this.props;
    const col = columns[columnIndex].dataKey;
    const isOrdered = orderBy === col;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.headerCell)}
        variant="head"
        style={{ height: headerHeight }}
        sortDirection={isOrdered ? order : false}
        align={'left'}
        onClick={this.sortHandler(col)}
      >
        <span>{label}</span>
        <TableSortLabel active={isOrdered} direction={isOrdered ? order : 'asc'} />
      </TableCell>
    );
  };

  cellDataGetter = ({ columnData, dataKey, rowData }) => {
    return _.get(rowData, dataKey);
  }

  render() {
    const { classes, columns, rowHeight, headerHeight, ContextMenu, ...tableProps } = this.props;
    //    console.log("tableProps",tableProps);
    let menu = null;
    //    console.log("this.state", this.state);
    //    console.log("ContextMenu", ContextMenu);
    if (this.state.menu.x && ContextMenu) {
      menu = <ContextMenu {...this.state.menu} close={this.closeContextMenu} />
      //      console.log("render menu:");
    }


    return (
      <>
        {menu}
        <AutoSizer>
          {({ height, width }) => (
            <Table
              height={height}
              width={width}
              rowHeight={rowHeight}
              gridStyle={{
                direction: 'inherit',
              }}
              headerHeight={headerHeight}
              className={classes.table}
              {...tableProps}
              rowClassName={this.getRowClassName}
            >
              {columns.map(({ dataKey, ...other }, index) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classes.flexContainer}
                    cellRenderer={this.cellRenderer}
                    cellDataGetter={this.cellDataGetter}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      </>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  ConextMenu: PropTypes.func,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

const useDataStyles = makeStyles(theme => ({
  root: { width: '100%', height: '100%', display: 'flex', flexDirection: 'column', background: theme.palette.background.paper },
  header: { height: 48 },
  grid: { flexGrow: 100 },
}));

export const FilterChip = ({ table, field, val, clear, onClick }) => {
  const m = useMeta(table, field);
  let { exact, partial, tol, ig, ref } = val;
  let txt = '';
  if (partial) txt = `${m.label} ~ ${partial}`;
  if (exact) txt = `${m.label} = ${exact}`;
  if (tol && ig) {
    txt = `${m.label} [${tol},${ig}]`;
  } else if (tol) {
    txt = `${m.label} >= ${tol}`;
  } else if (ig) {
    txt = `${m.label} <= ${ig}`;
  }
  if (ref) txt = `${m.label} = ${ref.label}`;

  return <Chip variant="outline" icon={<FilterList />} onClick={onClick} onDelete={clear} label={txt} />
}

export const FilterDialog = ({ table, open, close, filter, setFilter, fields }) => {
  const meta = useMeta(table);
  const submit = (values) => {
    const f = Object.entries(values).reduce((acc, [k, v]) => {
      if (v && (v.partial || v.exact || v.tol || v.ig)) {
        acc[k] = v;
      }
      return acc;
    }, {});
    setFilter(f);
    close();
  }

  //  console.log("filter dialog:", { table, meta, filter, fields });
  return <Dialog open={open} maxWidth='sm' onClose={close}>
    <Formik initialValues={filter} onSubmit={submit} >{({ handleSubmit, isSubmitting, isValidating, dirty }) => (
      <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        <DialogContent>
          {fields.map(f => {
            const m = meta[f];
            switch (m.type) {
              case 'date':
                return <Grid key={f} container>
                  <Grid item xs={6}>
                    <DateField name={f + '.tol'} label={m.label + " -tól"} />
                  </Grid>
                  <Grid item xs={6}>
                    <DateField name={f + '.ig'} label={m.label + " -ig"} />
                  </Grid>
                </Grid>
              case 'datetime':
                return <Grid key={f} container>
                  <Grid item xs={6}>
                    <StampField name={f + '.tol'} label={m.label + " -tól"} />
                  </Grid>
                  <Grid item xs={6}>
                    <StampField name={f + '.ig'} label={m.label + " -ig"} />
                  </Grid>
                </Grid>
              case 'int':
              case 'decimal':
                return <Grid key={f} container>
                  <Grid item xs={6}>
                    <TextField inputProps={{ type: "number" }} name={f + '.tol'} label={m.label + " -tól"} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField inputProps={{ type: "number" }} name={f + '.ig'} label={m.label + " -ig"} />
                  </Grid>
                </Grid>
              case 'enum':
                return <FormikField key={f} fullWidth component={Select} options={m.select.map((o, i) => ({ value: i + 1, label: o }))} label={m.label} />
              default:
                return <TextField name={f + '.partial'} label={m.label} key={f} />
            }
          })
          }
        </DialogContent>
        {isSubmitting && <LinearProgress />}
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            autoFocus
          >
            Szűrés
          </Button>
        </DialogActions>
      </form>
    )}
    </Formik>
  </Dialog>
}

export default function DataTable(props) {
  let { list, sort, comparator, columns, ContextMenu,children } = props;
  const classes = useDataStyles();
  const [order, setOrder] = useState(props.order || 'asc');
  const [orderBy, setOrderBy] = useState(props.orderBy || columns[0].dataKey);
  const [filter, setFilter] = useState({});
  const [filterdlg, showFilterDialog] = useState(false);
  const filters = Object.keys(filter);

  if (!list) return <Loading />
  if (filters.length) {
    filters.map(f => {
      let { exact, partial, tol, ig, ref } = filter[f];
      if (partial) {
        partial = partial.toLowerCase();
        list = list.filter(d => String(d[f]).toLowerCase().includes(partial));
      }
      if (exact) list = list.filter(d => d[f] == exact);
      if (tol) list = list.filter(d => d[f] >= tol);
      if (ig) list = list.filter(d => d[f] <= ig);
      if (ref) list = list.filter(d => d[f].id === ref.id);
    })
  }

  if (!sort) {
    if (!comparator) comparator = defaultComparator;
    const sortCol = columns.find(c => c.dataKey === orderBy);
    list.sort(comparator(order, orderBy, sortCol && sortCol.sortVal));
  }

  const requestSort = (col) => {
    const isAsc = orderBy === col && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(col);
    if (sort) sort(col, isAsc ? 'desc' : 'asc');
  }

  const showFilters = () => showFilterDialog(true);

  //  console.log("DataTable",list,props);
  return <div className={classes.root}>

    {props.header !== false && <div className={classes.header}>
      {props.filters ? <Button onClick={showFilters}>{list.length} sor <FilterList /></Button> : `${list.length} sor`}
      {filters.map(f => <FilterChip key={f} onClick={showFilters} table={props.table} field={f} val={filter[f]} clear={() => {
        let uj = { ...filter };
        delete uj[f];
        setFilter(uj);
      }} />)}
    </div>}
    {children}
    <div className={classes.grid}>
      <VirtualizedTable
        columns={columns}
        rowCount={list.length}
        rowGetter={({ index }) => list[index]}
        requestSort={requestSort}
        order={order}
        orderBy={orderBy}
        other={true}
        table={props.table}
        getRowClassName={props.getRowClassName}
        ContextMenu={ContextMenu}
        onRowClick={props.onRowClick}
      />
    </div>
    <FilterDialog table={props.table} fields={props.filters} open={filterdlg} close={() => showFilterDialog(false)} filter={filter} setFilter={setFilter} />
  </div>
}

export const linkColumn = (cell, row, column) => {
  if (!cell) return null;
  let props = {};
  if (Array.isArray(cell)) {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>{
      cell.map(c => {
        props[column.link || column.dataKey] = c;
        return <Link key={c.id || c} {...props} />
      })
    }</div>
  } else {
    props[column.link || column.dataKey] = cell;
    return <Link {...props} />
  }
}

export const pkeyColumn = (cell, row, column, table) => {
  let props = {};
  props[table] = row;
  return <Link {...props} />
}

export const enumColumn = n => cell => cell ? (n[cell - 1] || '?') : '';

export const stampColumn = st=>{
  st = stampToMoment(st); 
  return <div display="block"><div>{st.format('MM.DD')}</div><div style={{ color: '#ccc' }}>{st.format('HH:mm')}</div></div>
}