import { useEffect, useState } from 'react';
import { styled, alpha, InputBase, MenuItem, IconButton, Box, Menu, ListItemText, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));


export function SearchInput({setSearch,value}) {
  let [term, setTerm] = useState(value);
  let [debounce, setDebounce] = useState(false);

  return (
    <Search style={{background: term? '#ffffff99' : '#ffffff22', flexGrow:1}}>
      <SearchIconWrapper  >
        <SearchIcon/>
      </SearchIconWrapper>
      <InputBase
        style={{ flexGrow: 100 }}
        placeholder="Keresés…"
        value={term}
        onChange={
          ev => {
            let term = ev.target.value;
            setTerm(term);
            if (debounce) clearTimeout(debounce);
            setDebounce(setTimeout(() =>setSearch(term), 1000));
          }
        }
      />
    </Search>
  );
}