import { Protected } from "../data/auth";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { dateFromMoment, dateToMoment, nowdate } from "../../utils";
import { Dialog, registerDialog, useDialog } from '../components/Dialog';
import { Logout, Menu, Add, Refresh } from "@mui/icons-material";
import COMMANDS from '../../common/commands';
import { ConfirmProvider } from 'material-ui-confirm';
import { IconButton } from "@mui/material";
import OrderNr from "./OrderNr";
import OrderForm from "./OrderForm";
import { useParams } from "react-router-dom";


const AddOrder = () => {
  const p = useParams();
  const [order, setOrder] = useState(p.bc);

  return <Protected module="Szállítás kezdeményezése">
    {order ? <OrderForm order={order} close={() => setOrder(null)} /> : <OrderNr setOrder={setOrder} />}
  </Protected >
}


export default AddOrder;