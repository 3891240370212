import { Protected } from "../data/auth";
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from "@mui/material";


const OrderNr = ({ setOrder, close }) => {
  const [nr, setNr] = useState('');
  const keyUp = e => {
    console.log(e.target.value);
    if (e.key === 'Enter') {
      setOrder(nr);
    } else {
      setNr(e.target.value);
    }
  };

  return <Box style={{ display: 'flex', justifyContent: 'center', margin: 40 }}>
    <TextField onKeyUp={keyUp} placeholder="BC-s rendelés kód" />
    <Button variant="contained" color="primary" onClick={() => setOrder(nr)}>Betöltés</Button>
    {close ? <Button variant="" onClick={close}>Mégsem</Button> : null}
  </Box>
}


export default OrderNr;