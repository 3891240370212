import { Protected } from "../data/auth";
import "./styles.css";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { dateFromMoment, dateToMoment, format, nowdate } from "../../utils";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import { fetchJSON, postCommand, useJSON, useRefresh } from "../data/fetch";
import { SearchInput } from "../components/Search";
import { Logout, Menu, Add, Refresh } from "@mui/icons-material";
import COMMANDS from '../../common/commands';
import { Button, IconButton } from "@mui/material";
import { Formik, useField } from 'formik';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../components/input';
import DataTable from "../components/DataTable";

const drawerWidth = 240;


//registerPanel({ id: 'parcel', Component: ParcelPanel })


const Archive = ({ url }) => {
  const { setSidebar } = useSidebar();
  const from = moment().subtract(7, 'd');
  const [filter, setFilter] = useState({ from, to: moment(), term: '' });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchJSON('POST', url || '/delivery/archive', filter).then(setData);
  }, [filter])

  const refresh = () => {
    setFilter({ ...filter });
  }

  const submit = (values) => {
    setFilter({ ...filter, ...values });
  }


  return <Box sx={{ display: 'flex' }}>
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <Toolbar variant="dense">
        <IconButton onClick={()=>refresh()} style={{ color: '#fff' }}><Refresh /></IconButton>
        <Typography variant="h6" noWrap component="div">
          Kiszállítások
        </Typography>
        <SearchInput setSearch={(term) => setFilter({ ...filter, term })} value={filter.term} />
      </Toolbar>
    </AppBar>
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        zIndex: (theme) => theme.zIndex.drawer - 2,
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ p: 2 }}>
        <Formik initialValues={filter} onSubmit={submit}>{({ handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} autocomplete="off">
            <DateField name="from" label="Szállítás napja" />
            <DateField name="to" label="" />
            <EnumField name="ceg" label="Cég" options={[
              { value: 'MACMA', label: 'MACMA' },
              { value: 'Easy Gifts', label: 'Easy Gifts' },
              { value: 'Spark', label: 'Spark' },
            ]} />
            <TextField name="partner" label="Partner" />
            <TextField name="order" label="Bizonylat" />
            <TextField name="item" label="Cikk" />
            <Button variant="contained" type="submit">Keresés</Button>
          </form>
        )}
        </Formik>
      </Box>
    </Drawer>
    <Box style={{
      background: '#fff',
      flexGrow: 1,
      paddingTop: 50,
      height: '100vh'
    }}>
      <DataTable
        header={false}
        list={data}
        //  table="parcel"
        // onRowClick={onRowClick}
        columns={[
          { width: 100, label: 'Dátum', dataKey: 'date', format: format.date },
          { width: 180, label: 'Partner', dataKey: 'partner' },
          { width: 120, label: 'Order', dataKey: 'order', format: (col, val) => <>{val.order} <br />{val.salesline}</> },
          { width: 60, label: 'Menny.', dataKey: 'quantity' },
          { width: 300, label: 'Cikk', dataKey: 'item', format: (col, val) => <>{val.item} <br />{val.name}</> },
        ]}
      />
    </Box>
  </Box>
}


export default Archive;