
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import '../../../node_modules/typeface-roboto-multilang/latin-ext.css';
import config from '../config.json';

import { WindowDimensionsProvider } from '../components/Responsive';
import { AuthProvider } from '../data/auth';
import { SnackbarProvider } from 'notistack';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import './global.css';

//import Ajanlat from '../ui/ajanlat';
//import Planner from '../ui/planner';
//import Borze from '../ui/borze';
//import Op from '../ui/op';
//const Planner = lazy(() => import('../planner/PlannerApp.js'));
//const Driver = lazy(() => import('../driver/DriverApp.js'));
import Planner from '../planner/PlannerApp.js';
import Uzletkoto from '../uzletkoto/UzletkotoApp.js';
import Driver from '../driver/DriverApp.js';
import { FetchContext } from '../data/fetch';
import AddOrder from '../order/AddOrder';
import ArchiveApp from '../archive/ArchiveApp';
import PolcCimkeApp from '../polccimke/PolcCimkeApp';
import BeszerzoApp from '../beszerzes/BeszerzoApp';

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div'
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px'
        }
      }
    }
  },
  palette: {
    primary: { main: config.theme && config.theme.primary ? config.theme.primary : undefined },
    secondary: { main: config.theme && config.theme.secondary ? config.theme.secondary : undefined },
    background: {
      default: '#eee',
    },
  },
});

export const darkMode = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#fff !important'
        },
        selected: {
          color: '#fff !important',
          background: '#fffc'
        }
      }
    }
  },
});


export function AppContext({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={DateAdapter} locale={'hu-HU'}>
          <AuthProvider>
            <WindowDimensionsProvider>
              <FetchContext>
                {children}
              </FetchContext>
            </WindowDimensionsProvider>
          </AuthProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AppContext>
      <Router>
        <Suspense fallback={<div>...</div>}>
          <Switch>
            <Route exact path="/raktar">
              <Planner />
            </Route>
            <Route exact path="/uzletkoto">
              <Uzletkoto />
            </Route>
            <Route path="/sofor/:asset">
              <Driver />
            </Route>
            <Route path="/addorder/:bc">
              <AddOrder />
            </Route>
            <Route exact path="/addorder">
              <AddOrder />
            </Route>
            <Route path="/archivum">
              <ArchiveApp />
            </Route>
            <Route path="/polccimke">
              <PolcCimkeApp />
            </Route>
            <Route path="/beszerzes">
              <BeszerzoApp />
            </Route>

          </Switch>
        </Suspense>
      </Router>
    </AppContext>
  );
}

export default App;