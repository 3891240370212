import React, { useContext, createContext, useMemo, useCallback } from 'react';
import META from '../../meta.js';


export const useMeta = (table, field) => {
  if (field) return META[table][field];
  if (table) return META[table];
  return META;
}

export const MetaCtx = createContext();

export const useRecordMeta = () => useContext(MetaCtx);
export const useFieldMeta = (field) => useContext(MetaCtx).meta[field];
export const useCalculate = (field,form) => useContext(MetaCtx).calculate(field,form);

export const MetaContext = ({ rec, op, calculate, children }) => {
  const meta = useMeta(rec);
  const calc = useCallback((field,form) => async(f,v)=>{
    let newval = {...form.values};
    newval[f] = v;
    if (meta[field] && meta[field].calculate) {      
      meta[field].calculate(newval, form.setFieldValue, op);
    }
    if (calculate) await calculate(field, newval, form, op);
  }, [rec, meta, op, calculate]);

  const value = useMemo(() => ({ rec, meta, calculate:calc }), [rec, meta, calc])

  return <MetaCtx.Provider value={value}>{children}</MetaCtx.Provider>
}



let DEFVAL = {};
export const defval = (rec, val) => {
  if (!DEFVAL[rec]) {
    let meta = META[rec];

    if (!meta) return {};
    DEFVAL[rec] = Object.keys(meta).reduce((acc, f) => {
      acc[f] = meta[f].defval;
      return acc;
    }, {});
  }
  let ret = Object.assign({}, DEFVAL[rec], val);
  /*  let fields = Object.keys(ret);
    for (const f of fields) {
      if (val[f]) ret[f] = val[f];  //itt lehetne typecastolni, hogy csak a metának megfelelő értékeket vehessen fel.
    }*/
  return ret;
}

let NULLVAL = {};
export const nullval = rec => {
  if (!NULLVAL[rec]) {
    let meta = META[rec];

    if (!meta) return {};
    NULLVAL[rec] = Object.keys(meta).reduce((acc, f) => {
      acc[f] = meta[f].nullval;
      return acc;
    }, {});
  }
  return Object.assign({}, NULLVAL[rec]);
}
