import { useCallback, useMemo } from 'react';
import { IconButton, Button, Box, Paper, Grid, Typography, Link, Card, CardHeader, CardContent, Table, TableRow, TableCell, TableContainer, TableBody } from "@mui/material";
import { useSidebar } from '../components/Sidebar'
import { Header } from '../components/Header';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import { dateToMoment } from '../../utils';
import DataDisplay from '../components/DataDisplay'
import { Check, Delete, DoneAll, Edit, LocalShipping, Map, MoreVert } from '@mui/icons-material';
import { lightBlue } from '@mui/material/colors';
import { useDialog } from '../components/Dialog';
import StatusIcon from '../parcel/StatusIcon';
import { postCommand, useRefresh } from '../data/fetch';
import { COMMAND } from '../../common';

let bgcolor = {
  "MACMA": "#EE6600",
  "Easy Gifts": "#6B508B",
  "Spark": "#ADD342"
}

export default function ColumnPanel({ id, sidebar }) {
  let { setDialog } = useDialog();
  const refresh = useRefresh();

  let { asset, date, delivery } = sidebar.open.column;
  console.log("ColumnPanel", asset, date);
  const stops = delivery.map(d => {
    return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderBottom: '1px solid #ccc', display: 'flex' }} key={id.id}>
      <div style={{ maxWidth: 30, minWidth: 30, paddingLeft: 4, paddingRight: 8, whiteSpace: 'nowrap', }}><StatusIcon d={d} /></div>
      <div style={{ maxWidth: 30, minWidth: 30, paddingLeft: 4, paddingRight: 8, whiteSpace: 'nowrap', }}>{d.country}</div>
      <div style={{ maxWidth: 50, minWidth: 50, whiteSpace: 'nowrap', }}>{d.zip}</div>
      <div style={{ flexGrow: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{d.partner}</div>
      <div style={{ maxWidth: 36, minWidth: 36, whiteSpace: 'nowrap', }}>
        <IconButton><Edit onClick={() => setDialog({ id: 'delivery', rec: d })} /></IconButton>
      </div>
    </Typography>
  })

  const kiszall = async () => {
    for (let i = 0; i < delivery.length; i++) {
      let { id, parcel } = delivery[i];
      if (parcel[0].statusenum < 3) {
        await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'DELIVERED' });
      }
    }
    refresh();
  }


  return <Panel header={
    <Header color='#ffffff' bgcolor={asset.color || lightBlue[400]}
      title={asset.id}
      subtitle={dateToMoment(date).format('YYYY.MM.DD dddd')}
      sidebar={sidebar}
      caption={`${delivery.length} fuvar`}
    />
  }
    content={<Box sx={{ bgcolor: 'background.paper', pt: 2, overflow: 'auto', minHeight: '100%' }}>
      {stops}
      <div style={{ margin: 8, display: 'flex', justifyContent: 'space-around' }}>
        <Button variant="contained" color="primary" startIcon={<DoneAll />} onClick={kiszall}>Kiszállítva</Button>
      </div>
    </ Box>}
  />
}
